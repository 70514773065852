import React from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import landingPage from './components/landingPage';
import truckOwners from './components/truckOwners'
import truckPage from './components/truckPage';
import truckCard from './components/truckCard';
import noPageFound from './components/noPageFound';

const AppRouter = () => (
	<div>
		<Switch>
			{/*Landing Page */}
			<Route exact path="/" component={landingPage} />
			{/*Landing Page Truck Owners*/}
			<Route exact path="/truckowners" component={truckOwners} />
			{/*Truck profile read-only page */}
			<Route exact path="/trucks/:truckId" component={truckPage} />
			{/*Route for truck app clip*/}
			{/* <Route path="/:t=*" component={truckCard} /> */}
			{/* 404 route for if url doesn't exist*/}
			<Route path="*" component={noPageFound} />
			{/* 404 if truck ID is not found*/}
			<Route path="/trucks/: * " component={noPageFound} />
		</Switch>
	</div>
);
export default withRouter(AppRouter);
