import { Component } from 'react';
import firebase from '../tools/firebase';
import { withRouter } from 'react-router';
import { Container, Jumbotron, Col, Row, Nav, Tabs, Tab, Card } from 'react-bootstrap';
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import style from '../style/truckPage_ui.module.css';

class TruckPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			//ID of truck
			truckId: '',

			// truck information
			truckInfo: [],

			// truck image url
			truckHeroImgURL: '',

			// Truck image link from Firebase after promise has been returned
			truckHeroImg: '',

			// Comments
			comments: [],

			// Social media links
			socialMedia: [],
			facebook: [],
			instagram: [],
			twitter: [],
			youtube: [],

			// food type arrays that are associated with different food trucks
			desserts: [],
			extras: [],
			entrees: [],
			drinks: [],

			// Active key for menu
			entreeMenuActive: '',
			dessertMenuActive: '',
			drinkMenuActive: '',
			extrasMenuActive: '',

			active: ''
		};
	}

	// Using the componentDidMount lifecycle method to get asynchronous data before the page is rendered
	componentDidMount() {
		// GET ID of truck via URL param
		const id = this.props.match.params.truckId;
		this.setState({
			truckId: id
		});

		// Initiate firebase
		const db = firebase.firestore();

		// GET truck profile data by ID
		db.collection('trucks').doc(id).get().then((doc) => {
			let truckData = doc.data();
			this.setState({
				truckInfo: truckData,
				truckHeroImgURL: truckData.image,
				socialMedia: truckData.socialmedia
			});
			console.log(this.state.truckInfo);
			console.log(this.state.socialMedia);
			// firebase.storage().ref().child(this.state.truckHeroImgURL).getDownloadURL().then((url) => {
			// 	this.setState({
			// 		truckHeroImg: url
			// 	});
			// });

			// running a series of functions that searches the social media array and pulls the object out of the array and passes its
			// own specific state object based on the "key" property of the object.
			let social = this.state.socialMedia;

			function isFacebook(account) {
				return account.key === 'facebook';
			}
			this.setState({
				facebook: social.find(isFacebook)
			});

			function isInstagram(account) {
				return account.key === 'instagram';
			}
			this.setState({
				instagram: social.find(isInstagram)
			});

			function isTwitter(account) {
				return account.key === 'twitter';
			}
			this.setState({
				twitter: social.find(isTwitter)
			});

			function isYoutube(account) {
				return account.key === 'youtube';
			}
			this.setState({
				youtube: social.find(isYoutube)
			});
		});

		// GET comment data associated with truck ID
		db.collection("comments").where("truckID", "==", id)
		.get()
		.then((querySnapshot) => {
			const commentArray = [];
			querySnapshot.forEach((doc) => {
				// doc.data() is never undefined for query doc snapshots
				// console.log(doc.id, " => ", doc.data());
				let commentData = doc.data();
				commentArray.push(commentData)
				this.setState({
					comments: commentArray
				});
				// console.log("this.state.comments");
				//  console.log(this.state.comments);
			});
		})
		.catch((error) => {
			console.log("Error getting documents: ", error);
		});

		// function that returns the sub-collection of entree menu items for a truck

		db.collection('trucks').doc(id).collection('Entrees').get().then((querySnapshot) => {
			const entree_Menu_Array = [];
			querySnapshot.forEach((doc) => {
				// doc.data() is never undefined for query doc snapshots
				// console.log(doc.id, " => ", doc.data());
				let commentData = doc.data();
				entree_Menu_Array.push(commentData)
				this.setState({
					entrees: entree_Menu_Array
				});
				console.log("this.state.entrees");
				console.log(this.state.entrees);
			});
		})
		.catch((error) => {
			console.log("Error getting documents: ", error);
		});


		// GET menu data for Desserts associated with truck ID
		db.collection('trucks').doc(id).collection('Desserts').get().then((querySnapshot) => {
			const desserts_Menu_Array = [];
			querySnapshot.forEach((doc) => {
				// doc.data() is never undefined for query doc snapshots
				// console.log(doc.id, " => ", doc.data());
				let commentData = doc.data();
				desserts_Menu_Array.push(commentData)
				this.setState({
					desserts: desserts_Menu_Array
				});
				console.log("this.state.desserts");
				console.log(this.state.desserts);
			});
		})
		.catch((error) => {
			console.log("Error getting documents: ", error);
		});

		// GET menu data for Drinks associated with truck ID
		db.collection('trucks').doc(id).collection('Drinks').get().then((querySnapshot) => {
			const drink_Menu_Array = [];
			querySnapshot.forEach((doc) => {
				// doc.data() is never undefined for query doc snapshots
				// console.log(doc.id, " => ", doc.data());
				let commentData = doc.data();
				drink_Menu_Array.push(commentData)
				this.setState({
					drinks: drink_Menu_Array
				});
				console.log("this.state.drink");
				console.log(this.state.drink);
			});
		})
		.catch((error) => {
			console.log("Error getting documents: ", error);
		});
// 		db.collection('trucks').doc(id).collection('Drinks').get().then((res) => {
// 			const drink_Menu_Array = [];
// 
// 			res.forEach((data) => {
// 				let drink_Menu_image = firebase
// 					.storage()
// 					.ref()
// 					.child(data.data().image)
// 					.getDownloadURL()
// 					.then((url) => {
// 						return url;
// 					});
// 				drink_Menu_image.then((res) => {
// 					let imgDr = res;
// 
// 					const fetch_drink_menu_item = {
// 						new_image: imgDr,
// 						...data.data()
// 					};
// 
// 					drink_Menu_Array.push(fetch_drink_menu_item);
// 					data.data();
// 					this.setState({
// 						drinks: drink_Menu_Array
// 					});
// 				});
// 
// 				// console.log(this.state.drinks);
// 			});
// 		});

		// GET menu data for Extras associated with truck ID
		db.collection('trucks').doc(id).collection('Extras').get().then((querySnapshot) => {
			const extras_Menu_Array = [];
			querySnapshot.forEach((doc) => {
				// doc.data() is never undefined for query doc snapshots
				// console.log(doc.id, " => ", doc.data());
				let commentData = doc.data();
				extras_Menu_Array.push(commentData)
				this.setState({
					extras: extras_Menu_Array
				});
				console.log("this.state.extras");
				console.log(this.state.extras);
			});
		})
		.catch((error) => {
			console.log("Error getting documents: ", error);
		});
// 		db.collection('trucks').doc(id).collection('Extras').get().then((res) => {
// 			const extras_Menu_Array = [];
// 
// 			res.forEach((data) => {
// 				let extras_Menu_image = firebase
// 					.storage()
// 					.ref()
// 					.child(data.data().image)
// 					.getDownloadURL()
// 					.then((url) => {
// 						return url;
// 					});
// 
// 				extras_Menu_image.then((res) => {
// 					let imgEx = res;
// 
// 					const fetch_extra_menu_item = {
// 						new_image: imgEx,
// 						...data.data()
// 					};
// 
// 					extras_Menu_Array.push(fetch_extra_menu_item);
// 					data.data();
// 					this.setState({
// 						extras: extras_Menu_Array
// 					});
// 				});
// 
// 				// console.log(this.state.extras);
// 			});
// 		});
	}

	// // Onselect logic for menu tabs
	handleSelect = (eventKey) => {
		// when this button is clicked I need to somehow override the css
		if (eventKey === 1) {
			// console.log(`entrees ${eventKey}`);
			this.setState({ active: 1 });
		} else if (eventKey === 2) {
			this.setState({ active: 2 });
		} else if (eventKey === 3) {
			this.setState({ active: 3 });
		} else if (eventKey === 4) {
			this.setState({ active: 4 });
		}
	};

	render() {
		return (
			<div>
				<Container style={{ textAlign: 'center' }}>
					<Row>
						<Col>
							<Jumbotron fluid
								style={{
									backgroundImage: `url(${this.state.truckHeroImgURL})`,
									backgroundSize: 'contain',
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'center',
									height: '65vh',
									margin: '10px',
									backgroundColor: '#FFCC00'
								}}
							>
								<div>
									<Nav
										className="flex-column"
										style={{
											float: 'right',
											backgroundColor: '#4d4d4d',
											opacity: '0.68',
											borderRadius: '15px'
										}}
									>
										{/*Conditional logic Checking to see if the truck has a facebook page object*/}
										{!this.state.facebook || this.state.facebook === '' ? (
											<Nav.Link style={{ color: '#ffd11a' }}>
												<FaFacebookF />
											</Nav.Link>
										) : (
											<Nav.Link
												style={{ color: '#ffd11a' }}
												href={this.state.facebook.link}
												target="_blank"
											>
												<FaFacebookF />
											</Nav.Link>
										)}
										{/*Conditional logic checking to see if the truck has a instagram page object*/}
										{!this.state.instagram || this.state.instagram === '' ? (
											<Nav.Link style={{ color: '#ffd11a' }}>
												<FaInstagram />
											</Nav.Link>
										) : (
											<Nav.Link
												style={{ color: '#ffd11a' }}
												href={this.state.instagram.link}
												target="_blank"
											>
												<FaInstagram />
											</Nav.Link>
										)}
										{/*Conditional logic checking to see if the truck has a twitter page object*/}
										{!this.state.twitter || this.state.twitter === '' ? (
											<Nav.Link style={{ color: '#ffd11a' }}>
												<FaTwitter />
											</Nav.Link>
										) : (
											<Nav.Link
												style={{ color: '#ffd11a' }}
												href={this.state.twitter.link}
												target="_blank"
											>
												<FaTwitter />
											</Nav.Link>
										)}
										{/* <Nav.Link
						
										{/*Conditional logic checking to see if the truck has a youtube page object*/}
										{!this.state.youtube || this.state.youtube === '' ? (
											<Nav.Link style={{ color: '#ffd11a' }}>
												<FaYoutube />
											</Nav.Link>
										) : (
											<Nav.Link
												style={{ color: '#ffd11a' }}
												href={this.state.youtube.link}
												target="_blank"
											>
												<FaYoutube />
											</Nav.Link>
										)}
									</Nav>
								</div>
							</Jumbotron>

							<div
								style={{
									boxShadow: ' 0 2px 3px -1px rgba(0, 0, 0, 0.1)',
									padding: '10px'
								}}
							>
								<h2 style={{ color: '#808080', fontSize: '30px' }}>{this.state.truckInfo.title}</h2>
							</div>
							<br />
							<p>{this.state.truckInfo.description}</p>
						</Col>
					</Row>
					<Row>
						<Col>
							<Tabs fill bsPrefix={style.tabMain} onSelect={this.handleSelect}>
								<Tab
									eventKey={1}
									title="Entrees"
									activekey="1"
									bsPrefix={style.tabOption}
									value={this.state.entreeMenuActive}
								>
									{!this.state.entrees || this.state.entrees === '' ? (
										<div>
											<br />
											<Card
												style={{
													height: 'auto',
													width: 'auto',
													borderRadius: '16px',
													WebkitBoxShadow: '0 4px 6px -6px #222',
													MozBoxShadow: '0 4px 6px -6px #222',
													boxShadow: '0 4px 6px -6px #222'
												}}
											>
												<Card.Img
													variant="top"
													src=""
													style={{ height: 'auto', width: 'atuo' }}
												/>
												<Card.Body>
													<Card.Title>No Entree data found</Card.Title>
												</Card.Body>
											</Card>
											<br />
										</div>
									) : (
										<div className={style.wrapper}>
											<br />
											{this.state.entrees.map((food, index) => (
												<Card className={style.item} key={index}>
													<Card.Img
														variant="top"
														className={style.cardImg}
														src={food.image}
													/>
													<Card.Body>
														<Card.Title style={{ fontSize: '15px' }}>
															{food.name}
														</Card.Title>
														{/* <Card.Text className={style.cardTexts}>
															{food.description}
														</Card.Text> */}
													</Card.Body>
												</Card>
											))}

											<br />
										</div>
									)}
								</Tab>
								<Tab eventKey={2} title="Desserts" activekey="2">
									{!this.state.desserts || this.state.desserts === '' ? (
										<div>
											<br />
											<Card
												style={{
													height: 'auto',
													width: 'auto',
													borderRadius: '15px',
													WebkitBoxShadow: '0 4px 6px -6px #222',
													MozBoxShadow: '0 4px 6px -6px #222',
													boxShadow: '0 4px 6px -6px #222'
												}}
											>
												<Card.Img variant="top" src="" />
												<Card.Body>
													<Card.Title>No Dessert data found</Card.Title>
												</Card.Body>
											</Card>
											<br />
										</div>
									) : (
										<div className={style.wrapper}>
											<br />
											{this.state.desserts.map((food, index) => (
												<Card className={style.item} key={index}>
													<Card.Img
														variant="top"
														className={style.cardImg}
														src={food.image}
													/>
													<Card.Body>
														<Card.Title style={{ fontSize: '15px' }}>
															{food.name}
														</Card.Title>
													</Card.Body>
												</Card>
											))}
											<br />
										</div>
									)}
								</Tab>
								<Tab eventKey={3} title="Drinks" activekey="3">
									{!this.state.drinks || this.state.drinks === '' ? (
										<div>
											<br />
											<Card
												className={style.item}
												style={{
													height: 'auto',
													width: 'auto',
													borderRadius: '15px',
													WebkitBoxShadow: '0 4px 6px -6px #222',
													MozBoxShadow: '0 4px 6px -6px #222',
													boxShadow: '0 4px 6px -6px #222'
												}}
											>
												<Card.Img variant="top" src="" />
												<Card.Body>
													<Card.Title>No Drink data found</Card.Title>
												</Card.Body>
											</Card>
											<br />
										</div>
									) : (
										<div className={style.wrapper}>
											<br />
											{this.state.drinks.map((food, index) => (
												<Card className={style.item} key={index}>
													<Card.Img
														variant="top"
														src={food.image}
														className={style.cardImg}
													/>
													<Card.Body>
														<Card.Title style={{ fontSize: '15px' }}>
															{food.name}
														</Card.Title>
													</Card.Body>
												</Card>
											))}
											<br />
										</div>
									)}
								</Tab>
								<Tab eventKey={4} title="Extras" activekey="4">
									{!this.state.extras || this.state.extras === '' ? (
										<div>
											<br />
											<Card
												style={{
													height: 'auto',
													width: 'auto',
													borderRadius: '15px',
													WebkitBoxShadow: '0 4px 6px -6px #222',
													MozBoxShadow: '0 4px 6px -6px #222',
													boxShadow: '0 4px 6px -6px #222'
												}}
											>
												<Card.Img variant="top" src="" />
												<Card.Body>
													<Card.Title>No Extra data found</Card.Title>
												</Card.Body>
											</Card>
											<br />
										</div>
									) : (
										<div className={style.wrapper}>
											<br />
											{this.state.extras.map((food, index) => (
												<Card className={style.item} key={index}>
													<Card.Img
														variant="top"
														className={style.cardImg}
														src={food.image}
													/>
													<Card.Body>
														<Card.Title style={{ fontSize: '15px' }}>
															{food.name}
														</Card.Title>
													</Card.Body>
												</Card>
											))}
											<br />
										</div>
									)}
								</Tab>
							</Tabs>
							<hr />
						</Col>
					</Row>

					<div>
						<b style={{ float: 'left' }}>Reviews</b>
						<br />
						<hr />
						{!this.state.comments || this.state.comments === '' ? (
							<p>No comment data found</p>
						) : (
							this.state.comments.map((data, i) => {
								return (
									<div key={i}>
										<div style={{ float: 'left', color: 'grey' }}>
											<b style={{ color: '#ffd11a' }}>{data.userName}</b> on {data.dateofcomment}
										</div>

										<br />
										<p style={{ float: 'left', fontWeight: 'bold' }}>{data.comment}</p>
										<br />
										<br />
									</div>
								);
							})
						)}
					</div>
				</Container>
			</div>
		);
	}
}

export default withRouter(TruckPage);
