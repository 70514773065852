import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaHeart } from 'react-icons/fa';
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';

import style from '../style/landing_ui.module.css';
import logo from '../assets/LOGO.png';
import phone from '../assets/Phone@3xcopy.png';
import apple from '../assets/black.svg';
import google from '../assets/GetItOnGooglePlay_Badge_Web_color_English.png';
import gftlogo from '../assets/LOGO.png';
import header from '../assets/heading.png';

const LandingPage = () => {
return (
<div className={style.background}>
	<br />
	<Container>
		<Row className={style.pushFooter}>
			<Col idName={style.headerStyle}>
			<p className={style.body}>
				<img src={logo} alt="Grub Truck Logo" className={style.logo} />
				<br />
				<br />
				GrubTrucks is a free app that helps you find food trucks near you! Truck owners add their location in the app so you can use GPS to take you to their location!<br/><br/> If you are a food truck owner? Check out: <br/> 
				<a href="https://grubtrucks.info/truckowners" classname="btn">Grub Trucks for Food Truck Owners</a>
				<br />
				<br />
			<div class="table-responsive">
				<table className="table table-borderless">
					<tr>
						<td className="align-top">
							<a href="https://apps.apple.com/us/app/grub-trucks/id1116325681?itsct=apps_box_badge&amp;itscg=30200" target="blank"><img src={apple} className={style.appleButton} /></a><br/>
						</td>
						<td className="align-top">
							<a href="https://play.google.com/store/apps/details?id=com.webdmg.grub.truck" target="blank">
							<img src={google} className={style.googleButton} /></a><br/>
						</td>
					</tr>
					{/* <tr>
					<td className="align-top">
							<a href="https://grubtrucks.info/gft" classname="btn">
							<img src={gftlogo} className={style.gftlogo} />
							
						</a>
						</td>
					</tr> */}
				</table>
			</div>
			</p>

			</Col>
			<Col className={style.hide}>
			<div className={style.phoneCenter}>
				<img src={phone} alt="Grub Truck Logo" className={style.phone} />
			</div>
			</Col>
		</Row>

		<Row className={style.textContainers}>
			<Col className={style.hide}>
			<p>
				Copyright ©2021{' '}
				<a target="blank" href="https://webdmg.com/" style={{ color: '#0A4A7A' }}>
					WEBDMG
				</a>
				<br />

			</p>
			</Col>
			<Col className={style.socialMedia}>
			<a href="https://www.facebook.com/TrucksGrub/" style={{ color: '#333' }} target="blank">
				<FaFacebookF />
			</a> &nbsp;
			<a href="https://www.instagram.com/grub_trucks/" style={{ color: '#333' }} target="blank">
				<FaInstagram />
			</a> &nbsp;
			<a href="https://twitter.com/TrucksGrub" style={{ color: '#333' }} target="blank">
				<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
				  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
				</svg>
			</a> &nbsp;
			<a href="https://www.youtube.com/@GrubTrucks" style={{ color: '#333' }} target="blank">
				<FaYoutube />
			</a>&nbsp;
			<a href="https://www.threads.net/@grub_trucks" style={{ color: '#333' }} target="blank">
				<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-threads-fill" viewBox="0 0 16 16">
				  <path d="M6.81 9.204c0-.41.197-1.062 1.727-1.062.469 0 .758.034 1.146.121-.124 1.606-.91 1.818-1.674 1.818-.418 0-1.2-.218-1.2-.877Z"/>
				  <path d="M2.59 16h10.82A2.59 2.59 0 0 0 16 13.41V2.59A2.59 2.59 0 0 0 13.41 0H2.59A2.59 2.59 0 0 0 0 2.59v10.82A2.59 2.59 0 0 0 2.59 16M5.866 5.91c.567-.81 1.315-1.126 2.35-1.126.73 0 1.351.246 1.795.711.443.466.696 1.132.754 1.983q.368.154.678.363c.832.559 1.29 1.395 1.29 2.353 0 2.037-1.67 3.806-4.692 3.806-2.595 0-5.291-1.51-5.291-6.004C2.75 3.526 5.361 2 8.033 2c1.234 0 4.129.182 5.217 3.777l-1.02.264c-.842-2.56-2.607-2.968-4.224-2.968-2.675 0-4.187 1.628-4.187 5.093 0 3.107 1.69 4.757 4.222 4.757 2.083 0 3.636-1.082 3.636-2.667 0-1.079-.906-1.595-.953-1.595-.177.925-.651 2.482-2.733 2.482-1.213 0-2.26-.838-2.26-1.936 0-1.568 1.488-2.136 2.663-2.136.44 0 .97.03 1.247.086 0-.478-.404-1.296-1.426-1.296-.911 0-1.16.288-1.45.624l-.024.027c-.202-.135-.875-.601-.875-.601Z"/>
				</svg>
			</a>&nbsp;
			<a href="https://www.tiktok.com/@grub_trucks" style={{ color: '#333' }} target="blank">
				<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-tiktok" viewBox="0 0 16 16">
				  <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z"/>
				</svg>
			</a>
			</Col>
		</Row>
		<Row className={style.footer}>
			<Col>Developed with {' '}
			<a target="blank" href="https://webdmg.com/" style={{ color: '#0A4A7A' }}>
				<FaHeart style={{ color: 'red' }} /> by WEBDMG
			</a>
			 <br/>Veteran owned and operated.
			</Col>
		</Row>
	</Container>



</div>
);
};

export default LandingPage;