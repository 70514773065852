import React from 'react';
import { Helmet } from 'react-helmet';

// Mailchimp script code
class MailChimp extends React.Component {
	render() {
		return (
			<div>
				<Helmet>
					<script type="text/javascript" charset="utf-8" src={process.env.REACT_APP_MAILCHIMP_POPUP} />
					<script async="" src={process.env.REACT_APP_MAILCHIMP_JS} />
					<link rel="stylesheet" type="text/css" href={process.env.REACT_APP_MAILCHIMP_CSS} media="all" />
				</Helmet>
			</div>
		);
	}
}

export default MailChimp;
