import { BrowserRouter } from 'react-router-dom';
import AppRouter from './AppRouter';

const App = () => (
	<BrowserRouter>
		<AppRouter />
	</BrowserRouter>
);

export default App;

// router created= done
// firebase data needs to be passed as a URL param

// -> URL param passes data into API and generates/ maps data to component
// -> first thing would be to make the component UI
// -> work on the URL param and API so that when we go to the URL with the param id we can console log that 1 object

// 1. pass truck data to truck route, console.log data
// 2. create UI layout for truck profile data

// 1. pass user data to user route, console.log data
// 2. create UI layout for user profile data
